import Vue from "vue";

import { Button, Checkbox, Dialog, Divider, Empty, Form, FormItem, Input, Loading, Tooltip,Image, Message, Skeleton, SkeletonItem } from "element-ui";

const coms = [Button, Divider, Checkbox, Dialog, Form, FormItem, Input, Tooltip, Empty, Image,Message,Skeleton,SkeletonItem];

Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

export default {
    install(Vue, options) {
        coms.map((c) => {
            Vue.component(c.name, c);
        });
    },
};
