<template>
  <div class="home">
    <div v-if="loading">
      <div class="box-1 con">
        <div class="box-1-inner con-inner" style="width: 100%">
          <el-skeleton :rows="6" animated style="width: 100%">
            <template slot="template">
              <div class="col g32">
                <el-skeleton-item variant="h1" style="width: 20vw"></el-skeleton-item>
                <div class="col g16">
                  <el-skeleton-item style="width: 50vw"></el-skeleton-item>
                  <el-skeleton-item style="width: 40vw"></el-skeleton-item>
                </div>
                <el-skeleton-item variant="button"></el-skeleton-item>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
      <div class="box-2 con">
        <div class="box-2-inner con-inner" style="width: 100%">
          <el-skeleton :rows="6" animated style="width: 100%">
            <template slot="template">
              <div class="col g32 fc">
                <el-skeleton-item variant="h1" style="width: 20vw"></el-skeleton-item>
                <div class="col g16 fc">
                  <el-skeleton-item style="width: 50vw"></el-skeleton-item>
                  <el-skeleton-item style="width: 70vw"></el-skeleton-item>
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="item in dataList" :key="item.id">
        <!-- box1 banner-->
        <div
          class="box-1 con"
          v-if="item.id == 1"
          :style="{ '--background': `url('${item.image}')` }">
          <div class="box-1-inner con-inner">
            <div>{{ item.title }}</div>
            <div v-html="item.datails"></div>
            <div style="position: relative">
              <el-button
                round
                :size="innerWidth < 1000 ? 'small' : 'normal'"
                type="primary"
                @mouseover.native="handleShowCard"
                >咨询客服</el-button
              >
              <div
                v-if="showCard"
                class="home-card"
                id="hcard"
                @mouseover="showCard = true"
                @mouseleave="handleHideCard">
                <div>{{ chat.title }}</div>
                <div>
                  <div v-for="item in chat.hwyViewPsList" :key="item.id">
                    <div>
                      <el-image
                        :src="item.image"
                        style="max-width: 100%; max-height: 100%"
                        fit="contain"></el-image>
                    </div>
                    <div>{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- box2 如何获得华为云免费使用特权？-->
        <div class="box-2 con" v-if="item.id == 2">
          <div class="box-2-inner con-inner">
            <div class="title">{{ item.title }}</div>
            <div>
              <span v-for="i in item.hwyViewPlateList" :key="i.id">
                <span>{{ i.title }}</span>
                <span>{{ i.datails }}</span>
              </span>
            </div>
            <div v-html="item.datails"></div>
            <div>
              <!-- <el-button round type="primary" :size="innerWidth < 1000 ? 'small' : 'normal'">注册会员</el-button> -->
              <el-button
                round
                :size="innerWidth < 1000 ? 'small' : 'normal'"
                type="primary"
                @click="toAbout"
                >咨询客服</el-button
              >
            </div>
          </div>
        </div>

        <!-- box3 关于华为云与三匠云-->
        <div class="box-3 con" v-if="item.id == 3">
          <div class="box-3-inner con-inner">
            <div class="title">{{ item.title }}</div>
            <div>{{ item.datails }}</div>
            <div>
              <div>
                <img :src="item.image" alt="" />
              </div>
              <div>{{ item.reason }}</div>
              <div>
                <div v-for="i in item.hwyViewPlateList" :key="i.id">
                  <div>
                    <img :src="i.image" />
                  </div>
                  <div>{{ i.title }}</div>
                  <div>{{ i.datails }}</div>
                </div>
              </div>
            </div>
            <div>
              <!-- <el-button round type="primary" :size="innerWidth < 1000 ? 'small' : 'normal'">注册会员</el-button> -->
              <el-button
                round
                :size="innerWidth < 1000 ? 'small' : 'normal'"
                type="primary"
                @click="toAbout"
                >咨询客服</el-button
              >
            </div>
          </div>
        </div>

        <!-- box4 华为云产品精选推荐-->
        <div class="box-4 con" v-if="item.id == 4">
          <div class="box-4-inner con-inner">
            <div class="title">{{ item.title }}</div>
            <div>{{ item.datails }}</div>
            <div>
              <div v-for="i in item.hwyViewPlateList" :key="i.id">
                <div>
                  <img :src="i.image" />
                </div>
                <div>{{ i.title }}</div>
              </div>
            </div>
            <div>
              <!-- <el-button round type="primary" :size="innerWidth < 1000 ? 'small' : 'normal'">注册会员</el-button> -->
              <el-button
                round
                :size="innerWidth < 1000 ? 'small' : 'normal'"
                type="primary"
                @click="toAbout"
                >咨询客服</el-button
              >
            </div>
          </div>
        </div>

        <!-- box5 三匠云其他云产品代理-->
        <div class="box-5 con" v-if="item.id == 5">
          <div class="box-5-inner con-inner">
            <div class="title">{{ item.title }}</div>
            <div>
              <div v-for="i in item.hwyViewDiscountList" :key="i.id">
                <div>
                  <div>{{ i.title }}</div>
                  <div>
                    低至<span>{{ i.discount }}</span>
                  </div>
                </div>
                <div @click="toAbout">立即咨询</div>
              </div>
            </div>
          </div>
        </div>

        <!-- box6 注册三匠云会员 尊享VIP特权特惠-->
        <div class="box-6 con" v-if="item.id == 6">
          <div class="box-6-inner con-inner">
            <div class="title">{{ item.title }}</div>
            <div>
              <el-button
                round
                type="primary"
                :size="innerWidth < 1000 ? 'small' : 'normal'"
                @click="toAbout"
                >咨询客服</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <cloudFfooter></cloudFfooter>
    </div>
  </div>
</template>
<script>
  import { hwyViewData, getFooter } from "@/api/data";
  import cloudHeader from "@/components/header.vue";
  import cloudFfooter from "@/components/footer.vue";
  export default {
    components: { cloudHeader, cloudFfooter },
    data() {
      return {
        dataList: {},
        innerWidth: this.$store.state.innerWidth,
        loading: true,
        showCard: false,
        chat: {},
      };
    },
    methods: {
      init() {
        const p = {
          sort: "sort,asc",
          routeId: 1,
        };
        hwyViewData(p).then((res) => {
          if (res.status == 200) {
            this.dataList = res.data.content.filter((item) => item.id != 0);
            this.loading = false;
          }
        });

        getFooter().then((res) => {
          if (res.status == 200) {
            this.chat = res.data.hwyViewWrList.filter((item) => item.id == 1)[0];
          }
        });
      },
      toAbout() {
        this.$router.push({
          name: "关于我们",
          params: {
            id: 11,
          },
        });
      },

      handleShowCard() {
        this.showCard = true;
        this.$nextTick(() => {
          let ele = document.getElementById("hcard");
          ele.style.opacity = 1;
        });

        setTimeout(() => {
          this.$nextTick(() => {
            let ele = document.getElementById("hcard");
            if (ele) {
              ele.style.opacity = 0;
              this.showCard = false;
            }
          });
        }, 3000);
      },
      handleHideCard() {
        let ele = document.getElementById("hcard");
        ele.style.opacity = 0;
        this.showCard = false;
      },
    },
    created() {
      this.init();
    },
  };
</script>
<style lang="scss" scoped>
  .home-card {
    background: #fff;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 16px;
    width: fit-content;
    position: absolute;
    top: 0;
    left: 120px;

    > div:nth-child(1) {
      margin-bottom: 24px;
      font-weight: bold;
      color: var(--text-1);
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      > div {
        color: var(--text-1);
        font-weight: bold;
        text-align: center;
        font-size: 14px;

        > div:nth-child(1) {
          width: 146px;
          height: 146px;
          // background: var(--border-1);
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .home {
    user-select: none;
    margin-top: 60px;

    .title {
      font-size: 36px;
      font-weight: bold;
      height: 56px;
      line-height: 56px;
      text-align: center;
    }

    @media (max-width: 1600px) {
      .title {
        font-size: 32px;
      }
    }

    .box-1 {
      height: 650px;
      background: var(--background) no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;

      &-inner {
        display: flex;
        flex-direction: column;

        > div:nth-child(1) {
          font-weight: bold;
          font-size: 60px;
          height: 84px;
          line-height: 84px;
        }

        > div:nth-child(2) {
          font-size: 20px;
          margin-top: 12px;
          // height: 30px;
          line-height: 30px;
          width: 70%;
        }

        > div:nth-child(3) {
          margin-top: 40px;
        }
      }
    }

    @media (max-width: 1600px) {
      .box-1 {
        height: 460px;

        &-inner {
          > div:nth-child(1) {
            font-size: 40px;
            height: 60px;
            line-height: 60px;
          }

          > div:nth-child(2) {
            font-size: 14px;
            // height: 22px;
            line-height: 22px;
          }

          > div:nth-child(3) {
            margin-top: 24px;
          }
        }
      }
    }

    .box-2 {
      &-inner {
        display: flex;
        flex-direction: column;
        gap: 60px;

        > div:nth-child(2) {
          display: flex;
          justify-content: center;

          > span {
            width: 100%;
            max-width: 16vw;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fff;
            background: url("@/assets/home/vector.svg") no-repeat center;
            background-size: 100%;
          }
        }

        > div:nth-child(3) {
          line-height: 22px;
        }

        > div:nth-child(4) {
          display: flex;
          gap: 80px;
          justify-content: center;
        }
      }
    }

    .box-3 {
      background: var(--color-grey);

      &-inner {
        display: flex;
        flex-direction: column;
        gap: 60px;

        > div:nth-child(2) {
          margin: 0 auto;
          width: 80%;
          font-size: 14px;
          line-height: 22px;
        }

        > div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div:nth-child(1) {
            width: 304px;
            height: 432px;
            background: #fff;

            img {
              height: 100%;
            }
          }

          // 选择三匠云的云代理原因
          > div:nth-child(2) {
            writing-mode: vertical-lr;
            font-weight: bold;
            font-size: 20px;
            color: var(--color-main);
            letter-spacing: 2px;
          }

          > div:nth-child(3) {
            display: grid;
            grid-template-columns: 16vw 16vw 16vw;
            gap: 60px;

            > div {
              width: 100%;
              height: 184px;
              background: #fff;
              border-radius: 6px;
              // box-shadow: 0px 2px 10px 0px rgba(187, 187, 187, 0.2);
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 16px;
              border-radius: 8px;
              padding: 0 20px;
              box-sizing: border-box;

              > div:nth-child(1) {
                height: 30px;
                display: flex;
                justify-content: center;

                > img {
                  height: 100%;
                }
              }
            }

            > div:hover {
              box-shadow: 0 4px 16px 0 rgba(187, 187, 187, 0.2);
              transition: 0.3s;
            }
          }
        }

        @media (max-width: 1440px) {
          > div:nth-child(3) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            > div:nth-child(2) {
              writing-mode: horizontal-tb;
            }
          }
        }

        > div:nth-child(4) {
          display: flex;
          gap: 80px;
          justify-content: center;
        }
      }
    }

    .box-4 {
      &-inner {
        > div:nth-child(2) {
          margin-top: 12px;
          font-size: 14px;
          color: var(--text-2);
        }

        > div:nth-child(3) {
          margin-top: 60px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 80px;

          > div {
            img {
              width: 60px;
              height: 60px;
            }
          }
        }

        > div:nth-child(4) {
          margin-top: 60px;
          display: flex;
          gap: 80px;
          justify-content: center;
        }
      }
    }

    .box-5 {
      background: var(--color-grey);

      &-inner {
        > div:nth-child(2) {
          margin-top: 60px;
          justify-content: space-between;
          display: grid;
          grid-template-columns: 18% 18% 18% 18% 18%;
          row-gap: 24px;

          > div {
            background: #fff;
            border-radius: 16px;
            width: 100%;
            height: 218px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            > div:nth-child(1) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              line-height: 1.5;

              span {
                font-size: 20px;
                color: var(--color-imp);
              }
            }

            // 立即咨询
            > div:nth-child(2) {
              height: 64px;
              background: var(--color-main);
              color: #fff;
              line-height: 64px;
            }
          }
        }
      }
    }

    .box-6 {
      &-inner {
        > div:nth-child(2) {
          margin-top: 60px;
        }
      }
    }

    .box-2,
    .box-3,
    .box-4,
    .box-5,
    .box-6 {
      &-inner {
        text-align: center;
        padding: 76px 0;
      }
    }
  }

  @media (max-width: 1600px) {
    .title {
      font-size: 32px;
    }

    .home {
      .box-2,
      .box-3,
      .box-4,
      .box-5,
      .box-6 {
        &-inner {
          padding: 60px 0;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .home {
      margin-top: 48px;

      .title {
        font-size: 18px;
        height: 30px;
        line-height: 30px;
      }

      .box-1 {
        height: 220px;

        &-inner {
          > div:nth-child(1) {
            font-size: 18px;
            height: 28px;
            line-height: 28px;
          }

          > div:nth-child(2) {
            font-size: 10px;
            line-height: 20px;
            margin-top: 0;
            width: 80%;
          }

          > div:nth-child(3) {
            margin-top: 24px;
          }
        }
      }

      .box-2 {
        &-inner {
          gap: 40px;

          > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            margin-left: -20px;

            > span {
              width: 100%;
              max-width: none;
              height: 50px;
              font-size: 12px;
            }
          }

          > div:nth-child(3) {
            line-height: 2;
            width: 100%;
            font-size: 12px;
          }

          > div:nth-child(4) {
            gap: 20px;
          }
        }
      }

      .box-3 {
        &-inner {
          gap: 40px;
          font-size: 12px;

          > div:nth-child(2) {
            width: 100%;
            font-size: 12px;
            line-height: 2;
          }

          > div:nth-child(3) {
            > div:nth-child(1) {
              width: 70vw;
              height: 380px;
            }

            > div:nth-child(2) {
              font-size: 16px;
            }

            > div:nth-child(3) {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;

              > div {
                height: 160px;
                width: 40vw;
                gap: 14px;

                > div:nth-child(1) {
                  height: 24px;
                }
              }
            }
          }

          > div:nth-child(4) {
            gap: 20px;
          }
        }
      }

      .box-4 {
        &-inner {
          > div:nth-child(2) {
            font-size: 12px;
            line-height: 2;
          }

          > div:nth-child(3) {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            > div {
              font-size: 10px;
              white-space: nowrap;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          > div:nth-child(4) {
            margin-top: 40px;
            gap: 20px;
          }
        }
      }

      .box-5 {
        &-inner {
          > div:nth-child(2) {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            > div {
              border-radius: 8px;
              width: 100%;
              height: 120px;

              > div:nth-child(1) {
                span {
                  font-size: 16px;
                }
              }

              // 立即咨询
              > div:nth-child(2) {
                height: 40px;
                line-height: 40px;
              }
            }
          }
        }
      }

      .box-6 {
        &-inner {
          > div:nth-child(2) {
            margin-top: 20px;
          }
        }
      }

      .box-2,
      .box-3,
      .box-4,
      .box-5 {
        &-inner {
          padding: 60px 0;
        }
      }
    }
  }
</style>
