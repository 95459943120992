<template>
  <div id="app">
    <cloudHeader ref="header" :headerShow="!showAnc" :activeAnc="activeAnc" @handleViewTo="handleViewTo">
    </cloudHeader>
    <router-view />
    <!-- <cloudFfooter></cloudFfooter> -->
  </div>
</template>

<script>
import cloudHeader from "./components/header.vue";
import cloudFfooter from "./components/footer.vue";
export default {
  components: { cloudHeader, cloudFfooter },
  data() {
    return {
      showAnc: false,
      activeAnc: 0,
      activeAncIndex: 0,
      scrollTime: null
    };
  },
  methods: {
    handleViewTo(index) {
      let ele = document.getElementById(index);
      ele.scrollIntoView({ behavior: "smooth" });
      this.activeAnc = index;
    },
  },
  created() {
    this.$store.state.innerWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-1);
  font-size: 16px;

  .con {
    width: 100vw;
    padding: 0 10%;
    box-sizing: border-box;
  }

  .con-inner {
    box-sizing: border-box;
  }
}

@media (max-width: 1600px) {
  #app {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-1);
    font-size: 14px;

    .con {
      width: 100vw;
      padding: 0 5%;
      box-sizing: border-box;
    }

    .con-inner {
      box-sizing: border-box;
    }
  }
}
</style>
