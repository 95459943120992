import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "首页",
        component: HomeView,
    },
    {
        path: "/discount",
        name: "优惠促销",
        component: () => import("@/views/discount/index"),
    },
    {
        path: "/about",
        name: "关于我们",
        component: () => import("@/views/AboutView.vue"),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = "三匠云-" + to.name;
    window.scrollTo(0, 0);
    next();
});

export default router;
