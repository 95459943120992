import service from "@/utils/request";

// 查询路由
export function hwyRoute(params) {
    return service({
        url: `/api/usr/hwyRoute`,
        method: "get",
        params,
    });
}
