<template>
  <div class="footer con" :style="{ backgroundColor: bgColor }">
    <div class="footer-inner con-inner">
      <!-- box1 -->
      <div class="box-1">
        <div :style="{ color: fontColor }" v-for="item in hwyViewPlateList" :key="item.id">
          <div>
            <!-- <img :src="bgColor == '#303133' ? icon[0][0] : icon[0][1]" /> -->
            <img :src="bgColor == '#303133' ? item.image : item.figure" />
          </div>
          <div :style="{ color: fontColor }">{{ item.title }}</div>
        </div>
      </div>

      <!-- box2 -->
      <div class="box-2">
        <!-- 产品与服务 -->
        <div>
          <div class="title" :style="{ color: fontColor }">关于我们</div>
          <div :style="{ color: fontColorLight }">
            <div v-for="item in aboutList" :key="item.id" style="cursor: pointer;" @click="toAbout(item.id)">{{ item.title
            }}</div>
          </div>
        </div>
        <!-- 关于我们 -->
        <div>
          <div class="title" :style="{ color: fontColor }">产品与服务</div>
          <div :style="{ color: fontColorLight }">
            <div v-for="i in evList" :key="i.id" style="cursor: pointer;" @click="toDis(i.id)">{{ i.title }}</div>
          </div>
        </div>
        <!-- 二维码 -->
        <div>
          <div v-for="item in chat.hwyViewPsList" :key="item.id">
            <div class="col fc">
              <el-image :src="item.image"></el-image>
              <span  :style="{ color: fontColor }">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- box3 -->
      <div class="box-3" :style="{ color: fontColorLight }">
        Copyright©️2023
      </div>

      <!-- 二维码 -->
      <div class="code">
        <div class="code-card" id="card" @mouseover="showCard = true" @mouseleave="handleHideCard" v-if="showCard">
          <div>{{ chat.title }}</div>
          <div>
            <div v-for="item in chat.hwyViewPsList" :key="item.id">
              <div>
                <el-image :src="item.image" style="max-width: 100%; max-height: 100%;" fit="contain"></el-image>
              </div>
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="code-btn" @mouseover="handleShowCard">
          <img src="@/assets/code.png" />
          <div>咨询</div>
        </div>
      </div>

      <!-- top -->
      <div class="top" @click="toTop">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { getFooter, hwyViewData, hwyEvents } from "@/api/data"

export default {
  props: {
    bgColor: { type: String, default: "#303133" },
    fontColor: { type: String, default: "#ffffff" },
    fontColorLight: { type: String, default: "#c0c4cc" },
  },

  data() {
    return {
      showCard: false,
      icon: [
        [
          require("@/assets/footer-icon1.png"),
          require("@/assets/footer-icon1-b.png"),
        ],
        [
          require("@/assets/footer-icon2.png"),
          require("@/assets/footer-icon2-b.png"),
        ],
        [
          require("@/assets/footer-icon3.png"),
          require("@/assets/footer-icon3-b.png"),
        ],
        [
          require("@/assets/footer-icon4.png"),
          require("@/assets/footer-icon4-b.png"),
        ],
      ],
      hwyViewPlateList: [],
      hwyViewWrList: [],
      hwyFirm: [],
      chat: {},
      aboutList: [],
      evList: []
    };
  },
  created() {
    this.init()
    this.aboutUsInit()
    this.eveInit()
  },
  methods: {
    init() {
      getFooter().then((res) => {
        if (res.status == 200) {
          this.hwyViewPlateList = res.data.hwyViewPlateList.sort((a, b) => a.sort - b.sort)
          this.hwyViewWrList = res.data.hwyViewWrList.filter((item) => item.id != 1)
          this.chat = res.data.hwyViewWrList.filter((item) => item.id == 1)[0]
          this.hwyFirm = res.data.hwyFirm
        }
      })
    },
    aboutUsInit() {
      const p = {
        sort: 'sort,asc',
        routeId: 3
      }
      hwyViewData(p).then((res) => {
        if (res.status == 200) {
          this.aboutList = res.data.content.filter((item) => item.id != 0 && item.id != 8)
        }
      })
    },
    eveInit() {
      const pe = {
        sort: 'sort,asc'
      }
      hwyEvents(pe).then((res) => {
        if (res.status == 200) {
          this.evList = res.data.content
        }
      })
    },
    handleShowCard() {
      this.showCard = true
      this.$nextTick(() => {
        let ele = document.getElementById("card");
        ele.style.opacity = 1;
      })

      setTimeout(() => {
        this.$nextTick(() => {
          let ele = document.getElementById("card");
          if (ele) {
            ele.style.opacity = 0;
            this.showCard = false;
          }
        })
      }, 3000);
    },
    handleHideCard() {
      let ele = document.getElementById("card");
      ele.style.opacity = 0;
      this.showCard = false;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    toAbout(id) {
      if (this.$route.name == '关于我们') {
        this.$emit('about', id)
      } else {
        this.$router.push({
          name: '关于我们',
          params: { id }
        })
      }
    },
    toDis(id) {
      if (this.$route.name == '优惠促销') {
        this.$emit('disc', id)
      } else {
        this.$router.push({
          name: '优惠促销',
          params: { id }
        })
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.footer {
  height: 560px;
  // background: var(--text-1);
  user-select: none;
  color: #fff;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    .box-1 {
      display: flex;
      justify-content: space-between;
      //   padding-bottom: 32px;
      //   border-bottom: 1px solid var(--text-2);

      >div {
        display: flex;
        align-items: center;
        gap: 16px;
        // color: #fff;

        >div:nth-child(1) {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
          }
        }
      }
    }

    .box-2 {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      >div {
        >div:nth-child(2) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 40px;
          row-gap: 16px;
          // color: var(--text-4);
          font-size: 14px;

          a {
            font-size: 14px;
          }
        }
      }

      // 页脚二维码
      >div:nth-child(3) {
        display: flex;
        gap: 40px;
        justify-items: end;

        >div {}

        .el-image {
          width: 160px;
          height: 160px;
        }

        span {
          display: inline-block;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          margin-top: 8px;
        }
      }
    }

    .box-3 {
      text-align: center;
      font-size: 14px;
      // color: var(--text-3);
    }

    .code {
      position: fixed;
      top: 60%;
      right: 20px;
      display: flex;
      z-index: 101;

      &-card {
        background: #fff;
        padding: 20px;
        margin-right: 20px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border-radius: 16px;

        >div:nth-child(1) {
          margin-bottom: 24px;
          font-weight: bold;
          color: var(--text-1);
        }

        >div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          gap: 24px;

          >div {
            color: var(--text-1);
            font-weight: bold;
            text-align: center;
            font-size: 14px;

            >div:nth-child(1) {
              width: 146px;
              height: 146px;
              // background: var(--border-1);
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      &-btn {
        cursor: pointer;
        background: #fff;
        width: 56px;
        height: 72px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 12px;
        color: var(--text-2);
        align-items: center;
        justify-content: center;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .top {
      position: fixed;
      top: 70%;
      right: 20px;
      display: flex;
      z-index: 102;
      background: #fff;
      width: 56px;
      height: 56px;
      font-size: 18px;
      color: var(--text-2);
      align-items: center;
      justify-content: center;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    &-inner {
      .box-1 {
        display: none;

        >div {
          gap: 8px;

          >div:nth-child(1) {
            width: 20px;
            height: 20px;

            img {
              height: 100%;
            }
          }
        }
      }

      .box-2 {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;

        .title {
          font-size: 14px;
          margin-bottom: 12px;
        }

        >div {
          text-align: center;

          >div:nth-child(2) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 20px;
            row-gap: 16px;
            font-size: 12px;
          }
        }

        >div:nth-child(3) {
          // display: grid;
          // grid-template-columns: 1fr 1fr;
          // gap: 40px;
          // justify-items: end;

          >div {}

          .el-image {
            width: 120px;
            height: 120px;
          }

          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }

      .box-3 {
        font-size: 12px;
        padding-bottom: 12px;
      }

      .code {
        right: 10px;
        font-size: 14px;

        &-card {
          margin-right: 10px;

          >div:nth-child(2) {
            >div {
              font-size: 10px;

              >div:nth-child(1) {
                width: 120px;
                height: 120px;
              }
            }
          }
        }
      }

      .top {
        right: 10px;
      }
    }
  }
}
</style>