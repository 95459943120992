<template>
  <div>
    <div class="header con" v-if="headerShow || innerWidth <= 1000">
      <div class="header-inner con-inner">
        <div class="header-inner-logo">
          <img :src="hwyFirm.logo" />
        </div>

        <div class="header-inner-right">
          <div v-if="innerWidth > 1000 && hwyFirm.name">
            <span>联系方式：</span>
            <span>{{ hwyFirm.telephone }}</span>
            <span>（{{ hwyFirm.name }}）</span>
          </div>
          <!-- nav -->
          <div class="header-inner-menu">
            <div
              :class="$route.path == item.path ? 'active' : ''"
              @click="to(item.path)"
              v-for="item in routeList"
              :key="item.id">
              {{ item.name }}
            </div>
          </div>
          <!-- nav-h5 -->
          <div class="header-inner-menu-h5">
            <i
              :class="!openMenu ? 'el-icon-more' : 'el-icon-close'"
              @click="openMenu = !openMenu"></i>
            <div id="menu-h5" v-if="openMenu">
              <div
                :class="$route.path == item.path ? 'active' : ''"
                @click="to(item.path)"
                v-for="item in routeList"
                :key="item.id">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- banner-nav -->
    <div class="nav con" v-else>
      <div class="nav-inner con-inner">
        <div
          style="cursor: pointer"
          :class="activeAnc == item.id ? 'active' : ''"
          @click="handleViewTo(item.id)"
          v-for="item in evList.slice(0, 4)"
          :key="item.id">
          {{ item.plName }}
        </div>
      </div>
    </div>

    <!-- banner-nav-h5 -->
    <div class="nav" v-if="!headerShow && innerWidth <= 1000">
      <div class="nav-inner">
        <div
          style="cursor: pointer"
          :class="activeAnc == item.id ? 'active' : ''"
          @click="handleViewTo(item.id)"
          v-for="item in evList.slice(0, 4)"
          :key="item.id">
          {{ item.plTitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { hwyRoute } from "@/api/nav";
  import { hwyEvents, hwyFirm } from "@/api/data";

  export default {
    props: {
      headerShow: { type: Boolean, default: true },
      activeAnc: { type: Number, default: 0 },
      activeAncIndex: { type: Number, default: 0 },
    },
    watch: {
      activeAncIndex() {
        console.log(this.activeAncIndex);
      },
    },
    data() {
      return {
        routeList: [],
        innerWidth: this.$store.state.innerWidth,
        openMenu: false,
        evList: [],
        hwyFirm: [],
        // headerShow: true
      };
    },
    methods: {
      init() {
        const p = {
          sort: "sort,asc",
        };
        hwyRoute(p).then((res) => {
          if (res.status == 200) {
            this.routeList = res.data.content;
          }
        });
        hwyEvents(p).then((res) => {
          if (res.status == 200) {
            this.evList = res.data.content.filter((item) => {
              return item.status == 1;
            });
            this.loadEve = false;
          }
        });
        hwyFirm().then((res) => {
          if (res.status == 200) {
            this.hwyFirm = res.data.content[0];
          }
        });
      },
      to(path) {
        if (this.$route.path == path) {
          return;
        }
        this.$router.push(path);
        this.openMenu = false;
      },
      handleViewTo(index) {
        this.$emit("handleViewTo", index);
      },
    },
    created() {
      this.init();
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    z-index: 1000;
    height: 60px;
    background: #fff;
    user-select: none;
    position: fixed;
    top: 0;

    &-inner {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-logo {
        img {
          height: 32px;
        }
      }

      &-right{
        display: flex;
        align-items: center;
        gap: 40px;
      }

      &-menu {
        display: flex;
        gap: 80px;

        > div {
          line-height: 60px;
          position: relative;
        }

        > div:hover::after,
        .active::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 5px;
          background: var(--text-1);
          bottom: 0;
          animation: fadeIn 0.3s ease-in-out;
        }

        @keyframes fadeIn {
          0% {
            width: 0;
            opacity: 0;
          }

          100% {
            width: 100%;
            opacity: 1;
          }
        }
      }

      &-menu-h5 {
        display: none;
      }
    }
  }

  .nav {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    background: hsla(0, 0%, 94%, 0.7);
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    // display: none;
    width: 100vw;
    height: 48px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;

    .nav-inner {
      height: 48px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      > div {
        height: 48px;
        line-height: 48px;
        white-space: nowrap;
      }

      > div:hover {
        font-weight: bold;
        transition: 0.3s;
      }

      .active {
        font-weight: bold;
        background: #fff;
      }
    }
  }

  @media (max-width: 1000px) {
    .header {
      height: 48px;
      position: fixed;
      top: 0;
      border-bottom: 1px solid var(--border-1);

      // padding-bottom: 48px !important;
      // box-sizing: content-box !important;

      &-inner {
        height: 48px;

        &-logo {
          img {
            height: 24px;
          }
        }

        &-menu {
          display: none;
        }

        &-menu-h5 {
          display: block;

          > div:nth-child(2) {
            position: absolute;
            top: 48px;
            left: 0;
            background: var(--color-grey);
            width: 100vw;
            height: calc(100vh - 40px);

            > div {
              height: 46px;
              line-height: 46px;
              padding-left: 10%;
              position: relative;
            }

            .active {
              background: #fff;
            }

            .active:before {
              content: "";
              height: 20px;
              width: 4px;
              background: var(--text-1);
              position: absolute;
              left: 0;
              top: 13px;
            }
          }
        }
      }
    }

    .nav {
      top: 48px;

      &-inner {
        display: flex;

        > div {
          padding: 0 12px;
        }
      }
    }
  }
</style>
