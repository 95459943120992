import request from "axios";
import router from "@/router";
import { Message, Notification } from "element-ui";

const service = request.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // Headers: {
    //   Authorization: token,
    // },
});

service.interceptors.request.use((config) => {
    // const token = localStorage.getItem("token");
    // if (token) {
      // config.headers.Authorization = "Bearer 076d0721a71a451c83d57b0bab23aace";
    // }
    return config;
});

service.interceptors.response.use(
    (response) => {
        if (response.data.status == 401) {
            // router.replace({ path: "/login" });
        } else if (response.data.status == 200) {
            // Message.success(response.data.msg);
        } else if (!response.data.status) {
        } else {
            Notification.error({
                title: "错误",
                message: response.data.msg,
            });
        }
        const res = response.data;
        return res;
    },
    (error) => {
        Notification.error({
            title: "错误",
            message: error.response.data.msg,
        });
        return Promise.reject(error);
    }
);

export default service;
