import service from "@/utils/request";

// 查询页面数据
export function hwyViewData(params) {
    return service({
        url: `/api/usr/hwyViewData`,
        method: "get",
        params,
    });
}

// 查询企业信息
export function hwyFirm(params) {
    return service({
        url: `/api/usr/hwyFirm`,
        method: "get",
        params,
    });
}

// 查询页面数据
export function getFooter(params) {
    return service({
        url: `/api/usr/hwyViewData/getFooter`,
        method: "get",
        params,
    });
}

// 查询活动
export function hwyEvents(params) {
    return service({
        url: `/api/usr/hwyEvents`,
        method: "get",
        params,
    });
}

// 查询板块
export function hwyViewPlate(params) {
    return service({
        url: `/api/usr/hwyViewPlate`,
        method: "get",
        params,
    });
}
